<template>
    <div class="flex-row">
        <div class="flex-item">
            <el-table v-if="skudata_.length" :data="skudata_" border size="mini">
                <template v-for="(label, index) in columns">
                    <el-table-column v-if="label" :label="label"  :key="index">
                        <template slot-scope="scope">
                            {{scope.row.skulist_[index] && scope.row.skulist_[index].lealabel}}
                        </template>
                    </el-table-column>
                </template>

                <template v-for="(label,field) in skuitem">
                    <el-table-column v-if="label" :label="label"  :key="field" align="center">
                        <template slot-scope="scope">
							<el-input-number v-if="label=='库存'" v-model="scope.row[field]" :precision="0" :min="0" controls-position="right" size="mini" style="width: 110px;" />
                            <el-input-number v-else  v-model="scope.row[field]" :precision="2" :min="0" controls-position="right" size="mini" />
                        </template>
                    </el-table-column>
                </template>
            </el-table>    
        </div>
    </div>
</template>
<script>
export default {
    name:'skus-list',
    model: {
        prop: 'skudata',
        event: 'change'
    },
    props: {
        binid:{type:String, default:'binid'},
        skuid:{type:String, default:'skuid'},
        leaid:{type:String, default:'leaid'},
        label:{type:String, default:'label'},
        leafs:{type:String, default:'leafs'},
        skudata:{type:String,default:''},
        skuitem:{type:Object, default: () => {return {
			'total':'库存',
            'price':'商品价格',
            'price_cost':'商品成本',
        }}}
    },
    computed: {
        columns () {
            return this.skulist_.map(item => item[this.label]||false)
        }
    },
    data(){
        return {
            skudata_:[],
            skulist_:[]
        }
    },
    mounted(){
        this.$bus.on('el-goodsku-skulist',(skulist)=>{
            this.skulist_ = skulist;
        });
        this.skudata_ = this.skudata?JSON.parse( this.skudata ):[];
    },
    beforeDestroy() {
        this.$bus.off('el-goodsku-skulist');
    },
    methods: {
        // 计算每个sku后面有多少项
        getLevels(skulist_,level=[]) {
            for (let i = skulist_.length - 1; i >= 0; i--) {
                if (skulist_[i + 1] && skulist_[i + 1][this.leafs]) {
                    level[i] = skulist_[i + 1][this.leafs].length * level[i + 1] || 1
                } else {
                    level[i] = 1
                }
            }
            return level
        },
        
        // 笛卡尔积运算
        flatten(skulist_,stocks=[]) {
            let result = [],skuLen = 0,stockMap = {},level = this.getLevels(skulist_);
            if (skulist_.length === 0) return result;
    
            skulist_.forEach(skuitem => {
                if( !skuitem[this.leafs] || skuitem[this.leafs].length === 0 ){
                    return true;
                }
                skuLen = (skuLen || 1) * skuitem[this.leafs].length;
            })
            for (let i = 0; i < skuLen; i++) {
                let skus = [],binid=[],label=[];
                skulist_.forEach((skuitem, column) => {
                    let item = {},leaf = skuitem[this.leafs];
                    if (!leaf || leaf.length === 0){
                        return true
                    }

                    if (leaf.length > 1) {
                        item = skulist_[column][this.leafs][parseInt(i / level[column], 10) % leaf.length];
                    } else {
                        item = skulist_[column][this.leafs][0];
                    }
                    let skusitem = {
                        skulabel:skuitem[this.label],
                        lealabel:item[this.label],
                    }
                    skusitem[this.skuid] = skuitem[this.skuid],skusitem[this.leaid] = item[this.leaid]
                    skus.push(skusitem),binid.push(skuitem[this.skuid]+'-'+item[this.leaid]),label.push(item[this.label]);
                })

                stockMap[this.binid] = binid.join('@'),stockMap[this.label] = label.join('/');
                for(let field in this.skuitem){
                    if( stocks && stocks[result.length] && stocks[result.length][field] ){
                        stockMap[field] = stocks[result.length][field];
                    }else{
                        stockMap[field] = 0.00;
                    }
                }
                result.push({ ...stockMap, skulist_:skus });
            }
            return result;
        },
    },
    watch:{
        
        skulist_:{
            deep:true,
            handler(skulist_){
                this.skudata_ = this.flatten(skulist_,this.skudata_);
            }
        },
        skudata:{
            deep:true,
            handler(vals){
                this.skudata_ = this.skudata?JSON.parse( this.skudata ):[];
            }
        },
        skudata_:{
            deep:true,
            handler(vals){
                this.$emit('change',JSON.stringify(vals));
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>